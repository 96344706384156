import React from 'react';
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { styled } from '@mui/material';

interface SharedTimePickerProps {
  name: string;
  label: string;
  settings: {
    id: string;
    placeholder?: string;
    hint?: string;
    format?: string;
    disableAmpm?: boolean;
    errors?: boolean;
    values?: string;
    disabled?: boolean;
  };

  onChange?: (time: Date | null) => void;
}

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] border-gray-300 rounded-lg focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  error: 'bg-red-50 border-red-500 text-red-700',
  disabled: 'border-gray-300 text-gray-400 opacity-60',
};

const TextFieldWrap = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    height: '46px',
    borderRadius: '5px',
    color: '#6a6666',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    backgroundColor: 'rgb(249 250 251 / var(--tw-bg-opacity))',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#cacaca',
      borderWidth: '1px',
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#46988b',
        borderWidth: '1px',
      },
    },
  },
}));

export function SharedTimePicker({
  name,
  label,
  settings,
  onChange,
}: SharedTimePickerProps) {
  const {
    id,
    placeholder,
    hint,
    disableAmpm,
    disabled,
    errors,
    values,
    ...rest
  } = settings;
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(null);
  const [, meta] = useField(name);
  const baseLabelStyle = 'font-nunito mb-2 text-base text-gray-900 ';
  const baseMessageStyle = 'font-nunito text-base text-gray-500 ';
  const handleTimeChange = (time: Date | null) => {
    setSelectedTime(time);
    if (onChange) {
      onChange(time);
    }
  };

  return (
    <div className="flex flex-col">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <label htmlFor={name} className={baseLabelStyle}>
          {label}
        </label>
        <DesktopTimePicker
          value={selectedTime}
          format="HH:mm"
          onChange={handleTimeChange}
          disableOpenPicker={true}
          className={classNames(dynamicStyles.base, {
            [`${dynamicStyles.error}`]: errors,
            [`${dynamicStyles.disabled}`]: disabled,
          })}
          ampm={!disableAmpm}
          slots={{
            textField: (props: any) => (
              <TextFieldWrap
                size="small"
                {...props}
                id={id}
                name={name}
                variant="outlined"
                placeholder="HH:MM"
                className={classNames(dynamicStyles.base, {
                  [`${dynamicStyles.error}`]: errors,
                  [`${dynamicStyles.disabled}`]: disabled,
                })}
                fullWidth
              />
            ),
          }}
        />
        <div className={baseMessageStyle + (meta?.error ? 'text-red-500' : '')}>
          {meta?.error && meta?.touched ? (
            <div className="font-nunito mt-2 flex items-start text-base text-red-500">
              <ExclamationCircleIcon className="h-5 w-5" />
              <span className="ml-1">
                {typeof meta.error == 'object'
                  ? (Object.values(meta.error)[0] as any)
                  : meta.error}
              </span>
            </div>
          ) : (
            <div>
              {settings.hint && (
                <div className="font-nunito mt-2 text-base text-gray-500">
                  {settings.hint}
                </div>
              )}
            </div>
          )}
        </div>
      </LocalizationProvider>
    </div>
  );
}

export default SharedTimePicker;
