import {
  DTConsumer_Private,
  DTHoldingProxy,
  DTService_Offer_With_Template,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  TenantLink,
} from '@rabbit/data/types';
import {
  BL_Service,
  nestApiGetAllServiceOffers,
  nestApiRegisterHoldingsServiceByConsumer,
} from '@rabbit/bizproc/core';
import {
  Button,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import { useContext, useEffect, useState } from 'react';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { LIST_CURRENCIES, useGetConsumerData } from '@rabbit/bizproc/react';

export interface ModalAddNewServiceProps {
  handleClose: any;
  holdingId: string;
  tenantLink: TenantLink;
  holdingProxy?: DTHoldingProxy;
  consumePrivate?: DTConsumer_Private;
  shouldRefresh?: any;
  consumerLink:string;
}

const ModalAddNewService = ({
  handleClose,
  tenantLink,
  holdingId,
  holdingProxy,
  consumePrivate,
  shouldRefresh,
  consumerLink
}: ModalAddNewServiceProps) => {
  console.log('CALLED 1');
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);

  const [availableOffers, setAvailableOffers] = useState<
    DTService_Offer_With_Template[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Fetch relevant data either from props or the consumer data hook
  const data =
    holdingProxy || consumePrivate
      ? { holding_proxy: holdingProxy, consumer_private: consumePrivate }
      : useGetConsumerData(holdingId);
  const { holding_proxy, consumer_private } = data ?? {};
  const activedServicePlans = BL_Service.getLatestService(
    holding_proxy?.services
  );

  // Function to fetch available service offers from the API
  const getServiceOfferWithTemplate = async () => {
    setLoading(true);
    const res: any = await nestApiGetAllServiceOffers(
      `tenantLink=${tenantLink}`
    );
    if (res) {
      setAvailableOffers(res.data as DTService_Offer_With_Template[]);
    }
    setLoading(false);
  };

  // Function to handle the activation of a service plan
  const handleActivatePlan = async (plan: DTService_Offer_With_Template) => {
    try {
      setLoading(true);

      // Construct the payload for the service activation request
      const payload = {
        templateLink: plan.templateLink,
        holdingLink: holdingId,
        consumerLink: consumerLink,
        warrantorLink: getRootPersonaFromLexicon(
          t(PrincipalsFieldName),
          PersonaTypeSingleLetter.Warrantor
        ),
        price: {
          amount: plan.price.amount,
          currency: plan.price.currency,
        },
        tenantLink: tenantLink,
      };

      // Send the service activation request
      const response: any = await nestApiRegisterHoldingsServiceByConsumer(
        payload
      );
      console.log('Service Registration Response By Consumer:', response);

      // Stop loading and handle the response
      setLoading(false);

      // Check if the response was successful and perform necessary actions
      if (response && response?.holdingProxy) {
        if (shouldRefresh) shouldRefresh(response.holdingProxy?.services);
        handleClose(); // Close the modal after successful activation
      } else {
        console.error('Failed to activate service plan.'); // Log an error if the response is not successful
      }
    } catch (error) {
      // Handle any errors that occur during the request
      setLoading(false);
      console.error('Error during service activation:', error);
      // Optionally, you can show an error message to the user here
    }
  };

  useEffect(() => {
    if (tenantLink) getServiceOfferWithTemplate(); // Fetch service offers whenever tenantLink changes
  }, [tenantLink]);

  const getFormatedPrice = (price: any) => {
    const currencyCode = price ? price.currency : tenantInfo?.currency;
    const currency = LIST_CURRENCIES.find((item) => item.code === currencyCode);

    return currency
      ? `${currency.code} ${currency.symbol} ${price?.amount}`
      : 'Free';
  };

  return (
    <Modal
      className="relative w-[450px]"
      kind="generic"
      settings={{
        title: t('Select Add-on warranty'),
        handleClose,
      }}
    >
      {loading && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-md bg-white/50">
          <LoadingSpinner size="md" />
        </div>
      )}
      <div className="w-full space-y-4 p-4">
        {/* Loop through the available service plans and render them */}
        {availableOffers && availableOffers.length > 0 ? (
          availableOffers.map((plan, i) => {
            return (
              <div
                key={plan.docid}
                className={`rounded-lg border border-gray-300 p-4 shadow-sm ${
                  !plan.enabled ? 'cursor-not-allowed opacity-70' : ''
                }`}
              >
                <h3 className="text-lg font-semibold text-gray-800">
                  {plan.title}
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  {plan.template.description}
                </p>
                <p className="text-primary-900 mt-2 text-sm font-medium">
                  Price: {getFormatedPrice(plan.price)}
                </p>
                <Button
                  kind={'primary'}
                  className="bg-primary-900 hover:bg-primary-700 mt-3 w-full rounded-md py-2 text-white transition"
                  onClick={() => plan.enabled && handleActivatePlan(plan)}
                  disabled={
                    (activedServicePlans &&
                      activedServicePlans.templateLink === plan.templateLink) ||
                    false
                  }
                >
                  {activedServicePlans &&
                  activedServicePlans.templateLink === plan.templateLink
                    ? t('Activated')
                    : plan.price.amount === 0
                    ? t('Free')
                    : t('Add Service')}
                </Button>
              </div>
            );
          })
        ) : (
          <p>{t('There is no offers available for this Dealer')}</p>
        )}
      </div>
    </Modal>
  );
};

export default ModalAddNewService;
