import { DTService } from '@rabbit/data/types';
import React, { useContext } from 'react';
import CardWrapperWithHeader from '../CardWrapperWithHeader/CardWrapperWithHeader';
import LabelledItem from '../../atoms/LabelledItem/LabelledItem';
import { getFormattedDate } from '../../utils/helpers';
import { AppContext } from '@rabbit/app-context';
import { LIST_CURRENCIES } from '@rabbit/bizproc/react';

export interface ServiceViewerProps {
  service: DTService | null;
}

const ServiceViewer = (props: ServiceViewerProps) => {
  const { service } = props;
  const { tenantInfo } = useContext(AppContext);
  if (!service) return;

  const formateServicePayload = () => {
    if (!service) return null;
    const currencyCode = service.price
      ? service.price.currency
      : tenantInfo?.currency;
    const currency = LIST_CURRENCIES.find((item) => item.code === currencyCode);

    const price = currency
      ? `${currency.code} ${currency.symbol} ${service.price?.amount}`
      : 'Free';

    return {
      startDate: getFormattedDate(service.startDate),
      endDate: getFormattedDate(service.endDate),
      price,
      status: service.status,
      templateName: service.templateName,
      docid: service.docid,
      terms: service.templateName,
      templateLink: service.templateLink,
    };
  };

  const formatedService = formateServicePayload();

  return (
    formatedService && (
      <CardWrapperWithHeader
        title="Extended Warranty"
        // canCollapse={true}
        collapsedByDefault={false}
      >
        <div className="grid w-full grid-cols-2 gap-4 md:grid-cols-6 md:gap-6">
          {/* Plan Name */}
          <LabelledItem
            label="Plan Id"
            value={formatedService?.docid}
            labelClasses="font-nunito mb-[10px] text-sm text-gray-500"
            valueClasses="font-nunito font-medium text-[14px]"
          />

          {/* Price */}
          <LabelledItem
            label="Price"
            value={formatedService?.price}
            labelClasses="font-nunito mb-[10px] text-sm text-gray-500"
            valueClasses="font-nunito font-medium text-[14px]"
          />

          {/* Status */}
          <LabelledItem
            label="Status"
            value={formatedService?.status}
            labelClasses="font-nunito mb-[10px] text-sm text-gray-500"
            valueClasses="font-nunito font-medium text-[14px]"
          />

          {/* Status */}
          <LabelledItem
            label="Term"
            value={formatedService?.templateName}
            labelClasses="font-nunito mb-[10px] text-sm text-gray-500"
            valueClasses="font-nunito font-medium text-[14px]"
          />

          {/* Start Date */}
          <LabelledItem
            label="Start Date"
            value={formatedService?.startDate}
            labelClasses="font-nunito mb-[10px] text-sm text-gray-500"
            valueClasses="font-nunito font-medium text-[14px]"
          />

          {/* End Date */}
          <LabelledItem
            label="Expire In"
            value="Lifetime"
            labelClasses="font-nunito mb-[10px] text-sm text-gray-500"
            valueClasses="font-nunito font-medium text-[14px]"
          />

          {/* Contact Email */}
          {/* <LabelledItem
              label="Support Email"
              value={activatedPlan?.contact?.email}
            /> */}

          {/* Contact Phone */}
          {/* <LabelledItem
              label="Support Phone"
              value={activatedPlan?.contact?.phone}
            /> */}
        </div>
      </CardWrapperWithHeader>
    )
  );
};

export default ServiceViewer;
