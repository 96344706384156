import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';
import {
  FileStorageContext,
  getCompletedUploadsOfCategory,
  LIST_CURRENCIES,
} from '@rabbit/bizproc/react';
import { OliveFileUploader } from '../../organisms/upload-wrapper/OliveFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { UserUploadedDocument } from '@rabbit/data/types';
import { toast } from 'react-toastify';

export interface ModalAddNewServiceProps {
  handleClose: any;
  holdingId: string;
  personaId:string;
}

const ModalWarrantyTransfer = ({
  handleClose,
  holdingId,
  personaId,
}: ModalAddNewServiceProps) => {
  console.log('CALLED 1');
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [_,setUploadedFile] = useState<UserUploadedDocument[]>([]);
  const { moveCompletedUploadsToAttached, deleteFile, uploadQueueState } =
    useContext(FileStorageContext) || {};
  const { completed } = uploadQueueState ?? {};

  const tenantCurrency = LIST_CURRENCIES.filter(
    (items) => items.code === tenantInfo?.currency
  );
  const serialNumberProofUploads: CompletedUploadShape[] =
    getCompletedUploadsOfCategory(
      completed ?? [],
      UploadedFileCategories.SerialNumberProof
    );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Invalid email'))
      .required(t('Email is required')),
    firstname: Yup.string().required(t('First name is required')),
    lastname: Yup.string().required(t('Last name is required')),
    proof_of_warranty: Yup.array().optional(),
  });

  const handleSubmit = (values: any) => {
    setLoading(true);
    const prrofOfWarranties = serialNumberProofUploads?.flatMap((file) =>
      file.category === UploadedFileCategories.SerialNumberProof &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );
    if (
      moveCompletedUploadsToAttached &&
      prrofOfWarranties &&
      prrofOfWarranties.length > 0
    ) {
      moveCompletedUploadsToAttached(serialNumberProofUploads);
    }

    // Simulate a delay for the payment process
    setTimeout(() => {
      setLoading(false);
      toast.success('Warranty transfer has been initiated')
      // alert('Payment of 100 processed successfully!');
      handleClose(); // Close the modal after submission
    }, 2000);
  };

  return (
    <Modal
      className="relative w-[500px]"
      kind="generic"
      settings={{
        title: t('Transfer Warranty'),
        handleClose,
      }}
    >
      {loading && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center rounded-md bg-white/50">
          <LoadingSpinner size="md" />
        </div>
      )}
      <div className="w-full space-y-4 p-4">
        <Formik
          initialValues={{
            email: '',
            firstname: '',
            lastname: '',
            proof_of_warranty: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <Form className="space-y-2">
              <div className="grid grid-cols-2 gap-2">
                <Input
                  type="text"
                  name="firstname"
                  label={`${t('First Name')}*`}
                  settings={{
                    placeholder: 'John',
                  }}
                />
                <Input
                  type="text"
                  name="lastname"
                  label={`${t('Last Name')}*`}
                  settings={{
                    placeholder: 'Doe',
                  }}
                />
              </div>
              <div className="mt-4">
                <Input
                  type="email"
                  name="email"
                  label={`${t('Transfer to')}*`}
                  settings={{
                    placeholder: 'consumer@iwarranty.co',
                  }}
                />
              </div>
              <div>
                <OliveFileUploader
                  name="proof_of_warranty"
                  label={t('Additional Document')}
                  identifiers={{
                    category: UploadedFileCategories.Proof_Of_Warranty,
                    docType: {
                      docid: holdingId,
                      type: DocTypeShapeTypes.Warranty,
                    },
                    personaId: personaId,
                  }}
                  shouldAutoUpdateDocs={true}
                  onUploadCompleted={(files: UserUploadedDocument[]) => {
                    setUploadedFile(files);
                  }}
                  currentFiles={values.proof_of_warranty ?? []}
                  maxFiles={1}
                  accepts={['image/*']}
                />
              </div>
              <div className="mt-4">
                <Button type="submit" kind="primary" className="w-full">
                  {t(
                    `Initiate transfer ${
                      tenantCurrency &&
                      tenantCurrency.length > 0 &&
                      tenantCurrency[0].symbol
                    }100`
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalWarrantyTransfer;
