import MenuItem from '@mui/material/MenuItem';
import { Select, SelectChangeEvent, styled } from '@mui/material';

export interface MenuListDataProps {
  value: string;
  label: string;
  menuItem: {
    label: string;
    value: string;
  }[];
  handleChange: (event: SelectChangeEvent<any>) => void;
  selectSize?: 'small' | 'medium';
}

const MenuItemCustom = styled(MenuItem)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'normal !important',
  fontFamily: 'nunito',
  color: theme.palette.text.primary,
  '& .Mui-selected': {
    fontSize: '14px',
    fontWeight: 'normal !important',
    fontFamily: 'nunito',
  },
}));

const SelectCustom = styled(Select)(({ theme }) => ({
  fontFamily: 'nunito',
}));

export function MenuListSelect({
  value,
  label,
  menuItem,
  handleChange,
  selectSize = 'small',
}: MenuListDataProps) {
  return (
    <SelectCustom
      variant="standard"
      size={selectSize}
      value={value}
      label={label}
      className="font-nunito"
      onChange={handleChange}
    >
      {menuItem.map((data: any, index: number) => (
        <MenuItemCustom
          className="font-nunito font-extralight"
          key={index}
          value={data.value}
        >
          {data.label}
        </MenuItemCustom>
      ))}
    </SelectCustom>
  );
}
