import {
  SetStateAction,
  createContext,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { LoadingSpinner } from '../elements/shared-components/src/index';
import { merge } from 'ts-deepmerge';
import BaseConfig from '../configs/src/base';
import React from 'react';
import { RCFN, RCOParamMap } from '../configs/src/utils/render-control';
import { subscribeDocument } from '../utils/api/api';
import { DTTenant_Public, TenantInfo } from '../data/types/src/index';

export interface ConfigInterface {
  tenantInfo: DTTenant_Public['info'] | undefined;
  config: typeof BaseConfig;
  setConfig: Dispatch<SetStateAction<typeof BaseConfig>>;
  RenderControl: <K extends keyof RCOParamMap>(
    option: K,
    params: RCOParamMap[K]
  ) => boolean;
}

const AppContext = createContext<ConfigInterface>({
  tenantInfo: undefined,
  config: BaseConfig,
  setConfig: () => {},
  RenderControl: () => false,
});

type ConfigProviderWrapperProps = {
  children: React.ReactNode;
};

const AppContextProviderWrapper = ({
  children,
}: ConfigProviderWrapperProps) => {
  const [config, setConfig] = useState<typeof BaseConfig>(BaseConfig);
  const [isLoading, setIsLoading] = useState(true);
  // const isEmulator = import.meta.env.VITE_FIREBASE_MODE === 'EMULATOR';
  // const localConfig = localStorage.getItem('CONFIG');
  const [activeTenantLink, setActiveTenantLink] = useState(
    localStorage.getItem('activeTenant')
  );
  const [tenantInfo, setTenantInfo] = useState<TenantInfo>();

  // console.debug('activeTenantLink', activeTenantLink); // @TEST_REMOVE_LATER

  useEffect(() => {
    async function getTenantConfig(tenantLink: string) {
      const tenant = tenantLink.toLowerCase();
      try {
        return await import(`../configs/src/${tenant}.ts`);
      } catch (e) {
        console.warn(`File ${`../configs/src/${tenant}`} not found`);
        return BaseConfig;
      }
    }

    function updateActiveTenantLink() {
      console.debug('Active Tenant Link Updated');
      setActiveTenantLink(localStorage.getItem('activeTenant'));
    }

    window.addEventListener('activeTenantChange', updateActiveTenantLink);

    if (activeTenantLink) {
      void getTenantConfig(activeTenantLink).then((config) => {
        const finalConfig = merge(
          BaseConfig,
          config.default as typeof BaseConfig
        );

        setConfig(finalConfig);
        setIsLoading(false);
      });

      const unSub = subscribeDocument<DTTenant_Public>(
        `tenant_public/${activeTenantLink}`,
        (doc) => {
          setTenantInfo(doc.data()?.info);
          console.log('==== got info', doc.data(), doc.data()?.info);
        }
      );

      // console.debug('config', config || '{}'); // @TEST_REMOVE_LATER
      return () => {
        window.removeEventListener(
          'activeTenantChange',
          updateActiveTenantLink
        );

        unSub();
      };
    }
  }, [activeTenantLink]);

  if (!activeTenantLink) return <LoadingSpinner size="md" />;

  const RenderControl: ConfigInterface['RenderControl'] = (option, params) => {
    return RCFN[option](config, params);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Provider return                              */
  /* -------------------------------------------------------------------------- */
  if (!RenderControl || !setConfig || isLoading)
    return <LoadingSpinner size="lg" />;

  return (
    <AppContext.Provider
      value={{
        tenantInfo,
        config,
        setConfig,
        RenderControl,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProviderWrapper };
