import {
  Z_ConsumerLink,
  Z_HoldingLink,
  Z_PersonaLink,
  Z_RecorderCommitBlock,
  Z_RecorderCommitResult,
  Z_TenantLink,
  Z_WarrantorLink,
} from '@rabbit/data/types';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import { z } from 'zod';
import { ICF_Base } from '../base';
import { APICallParamsBase } from '@rabbit/elements/shared-types';
import { Z_CaseflowCaseTypes } from '../../caseflow';

/* -------------------------------------------------------------------------- */
/*                                Register Case                               */
/* -------------------------------------------------------------------------- */

// WIP. I don't love the current setup.
export enum RegisterCaseOrigins {
  /* Olive consumer user creates a claim from their holding. Currently set up based on Shelta, needs to be generalized*/
  OLIVE_HOLDING = 'olive-holding',
  SAGE_LITE = 'sage-lite',
  //TODO
  /** Using the Shelta configuration, sage user creates a claim for an existing or new consumer user, and for a new or existing holding. */
  SAGE_SHELTA = 'sage-shelta',
  /** Using the Fatbikes configuration, user  */
  SAGE_FATBIKES = 'sage-fatbikes',
  /** Non logged in olive consumer user creates a claim from a non-existing holding (which will also be created in the process).
   * Flow needs to be revisited as I don't remember the specifics  -DC
   */
  OLIVE_HOLDING_NLIC = 'olive-holding-nlic',
}

export const Z_RegisterCaseOrigins = z.nativeEnum(RegisterCaseOrigins);

// TODO: I like the idea of keeping these definitions in the same file as the cloud function spec
// and then exporting them for use in other files, looks very tidy. I will try to remember to move
// the definitions on the use*API.ts files to the same file as the cloud function spec. - DC
export type RegisterCase_Payload = Omit<
  z.infer<typeof ICF_RegisterCase>,
  keyof APICallParamsBase
>;

// This CF is meant to be used for creating all types of cases in the system. The origin determines the flow. A WIP. -DC
export const ICF_RegisterCase = z
  .object({
    origin: Z_RegisterCaseOrigins, // Origin determines the flow.
    caseType: Z_CaseflowCaseTypes, // The type of case being created. E.g repair, lite
    tenantLink: Z_TenantLink, // the active tenant
    // Optional params
    holdingLink: z.optional(Z_HoldingLink).nullable(),
    consumerLink: z.optional(Z_ConsumerLink).nullable(),
    // Used in Fatbikes (and eventually perhaps in claims-lite as well) flow
    repairerLink: z.optional(Z_PersonaLink).nullable(),
    // Used in claims lite
    warrantorLink: z.optional(Z_WarrantorLink).nullable(),
    installerLink: z.optional(Z_PersonaLink).nullable(),
    warrantyDealerLink: z.optional(Z_PersonaLink).nullable(),
    formData: z.record(z.any()).optional(), // ZOD acting up again and removing a lot of fields. Going with the any for
    newHoldingLink: z.optional(Z_HoldingLink).nullable(), //for holdingLink generated at FE
    newConsumerLink: z.optional(Z_ConsumerLink).nullable(), //for ConsumerLink generated at FE
    // formData: z
    //   .union([
    //     Z_Caseflow_CaseCreation_Olive_FormDataShape,
    //     Z_Caseflow_CaseCreation_Sage_Shelta_FormDataShape,
    //     Z_Caseflow_CaseCreation_Sage_Lite_FormDataShape,
    //     Z_Caseflow_CaseCreation_Fatbikes_FormDataShape,
    //   ])
    //   .optional(),
    // sagePersonaLink: Z_PersonaLink.optional(), // the Persona who will be handling this case on Sage. E.g repairer for Shelta
    // isSRV: z.boolean().optional(),
  })
  .merge(ICF_Base);

export const OCF_RegisterCase = z.object({
  caseId: z.string(),
  holdingLink: Z_HoldingLink.optional().nullable(),
  consumerLink: Z_ConsumerLink.optional().nullable(),
});

export const CFC_RegisterCase = MakeCloudFunctionSpec(
  'api/legacy/cfcregistercase',
  ICF_RegisterCase,
  OCF_RegisterCase
);

/* -------------------------------------------------------------------------- */
/*                          Carry out recorder commit                         */
/* -------------------------------------------------------------------------- */

// Note that I'm not including ICF_Base here. I don't think it's necessary in the context where it'll be used
// and I also have no idea how I would push in the request id and apiVersion right now.
// Of course, if it ends up being necessary, life will find a way. - DC

export const ICF_CaseFlowCarryOutRecorderCommit = z.object({
  block: Z_RecorderCommitBlock,
});
export const OCF_CaseFlowCarryOutRecorderCommit = Z_RecorderCommitResult;

export const CFC_CaseFlowCarryOutRecorderCommit = MakeCloudFunctionSpec(
  'api/legacy/cfccaseflowcarryoutrecordercommit',
  ICF_CaseFlowCarryOutRecorderCommit,
  OCF_CaseFlowCarryOutRecorderCommit
);
